export const isSubstring = (string, subString) => (
  string
    ?.trim()
    .toLowerCase()
    .includes(subString.trim().toLowerCase())
)

export const shortDate = (longDate) => (longDate ? longDate.slice(0, 10) : '-')

export const formatDate = (date) => new Date(date).toLocaleString('es-CL', { timeZone: 'America/Santiago' })

export const getFormattedDate = (date) => {
  if (!date) return '-'
  const formattedDate = formatDate(date)
  return shortDate(formattedDate)
}

export const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, '+')
    .replace(/_/g, '/')

  const rawData = atob(base64)

  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
