import { React, useContext } from 'react'

import { notificationContext } from '../../contexts/NotificationContext'


const PushNotification = () => {
  const { salesNotifications, markNotificationsAsRead } = useContext(notificationContext)

  const handleReload = () => {
    markNotificationsAsRead()
    window.location.reload()
  }

  if (salesNotifications.every(notification => notification.read)) return null

  return (
    <div className="fixed inset-x-0 bottom-10 z-10 mx-auto flex w-4/5 animate-[slideUp_0.3s_ease-out] items-center justify-center gap-2 rounded-xl bg-medium-blue px-6 py-3 text-white md:w-fit">
      <h1 className="text-base font-medium md:text-lg">Tienes un nuevo pedido</h1>
      <button
        type="button"
        className="rounded-md border border-white px-4 py-1.5 text-sm font-medium"
        onClick={handleReload}
      >
        Actualizar
      </button>
    </div>
  )
}

export default PushNotification
