import React from 'react'

const PackageSelection = ({isSelected, product, toggleProductSelection, viewPacking}) => {
  const { sku, name, imageUrl, quantity } = product
  return (
    <button
      type="button"
      key={sku}
      onClick={() => toggleProductSelection(product)}
      className={`flex w-[90%] items-center gap-2 self-center rounded-xl p-4 transition-all
        duration-500 md:w-full md:gap-4 ${isSelected ? 'border-2 border-green' : 'border-2 border-transparent'
      } bg-white ${viewPacking ? 'mb-10' : 'max-h-0 opacity-0'}`}
    >
      <img className="h-auto w-20 rounded-lg" src={imageUrl} alt="Product" />
      <div className="flex w-2/3 flex-col gap-y-1 text-left text-xs font-light md:text-sm">
        <div>{name}</div>
        <div className="text-xs opacity-50">{`Cantidad: ${quantity}`}</div>
        <div className="text-xs">SKU - {sku}</div>
      </div>
      <div
        className={`flex h-4 w-4 items-center  justify-center rounded-full border-2 md:h-6 md:w-6 ${
          isSelected ? 'border-green' : 'border-gray-300 bg-transparent'
        }`}
      >
        {isSelected && <div className="h-5/6 w-5/6 rounded-full bg-green" />}
      </div>
    </button>
  )
}

export default PackageSelection
