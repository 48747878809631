import React from 'react'

import { style } from '../../constants/notifications'
import { formatDate } from '../../utils/dates'

const PackageInformation = ({
  infoPackage,
  viewPackageDetails,
  setViewPackageDetails,
  isMobile
}) => {

  const viewBtnStyle = `${style[infoPackage.deliveryType]?.lightBG} text-${
    style[infoPackage.deliveryType]?.color
  }`

  const packageData = {
    // Pedido: infoPackage.packageId,
    'Orden de Venta': infoPackage.orderId,
    'Fecha de Compra': formatDate(infoPackage.createdAt),
    'Código Pinflag': infoPackage.pinflagId
  }

  const productsCount = infoPackage?.products?.length

  const packageCustomerName = `${infoPackage.customer?.nombrepersona || 'Nombre'} ${infoPackage.customer?.apellidopersona || 'apellido'}`

  if (isMobile) {
    return (
      <div className="mb-4 mt-24 flex w-3/5 flex-col items-center gap-y-2">
        <div className="text-center text-lg font-medium text-white">Prepara el pedido de {packageCustomerName}</div>
        <div className="w-2/3 text-center text-sm font-light text-white">Guarda {productsCount === 1 ? 'el producto' : `los ${productsCount} productos`} dentro de una bolsa
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="mb-4 w-full text-left">Pedido</div>
        <div className="w-full bg-white">
        <div className="relative flex h-28 w-full flex-col justify-center rounded-md bg-white px-7 shadow-lg">
            <div className="text-left text-xl font-medium">{packageCustomerName}</div>
            <div className="text-left font-light opacity-50">{`${
            productsCount || 0
            } producto${productsCount > 1 ? 's' : ''}`}</div>
            <button
            className={`absolute right-[0%] top-[50%] mx-10 translate-y-[-50%]
                rounded-full px-6 py-1 text-base ${viewBtnStyle}`}
            type="button"
            onClick={() => setViewPackageDetails((prev) => !prev)}
            >
            Ver detalle
            </button>
        </div>
        <div
            className={` no-scrollbar w-full overflow-y-auto rounded-md transition-all 
            duration-500 ${viewPackageDetails ? 'mt-2' : 'max-h-0 opacity-0'}`}
        >
            <div className="grid w-full grid-cols-2 gap-4 bg-white p-7 text-left">
            {Object.keys(packageData).map((key) => (
                <div key={key}>
                <div className="text-xl font-medium">{key}</div>
                <div className="text-base font-light opacity-50"> {packageData[key]}</div>
                </div>
            ))}
            <div className="col-span-2 text-left font-light opacity-50">Detalle productos</div>
            {infoPackage.products?.map((product) => (
                <div
                key={product.sku}
                className="flex items-center gap-4 rounded-xl bg-extra-light-gray p-4"
                >
                <img className="h-auto w-20 rounded-md" src={product.imageUrl} alt="Product" />
                <div className="text-left text-sm font-light">
                    <div>{product.name}</div>
                    <div>{`Cantidad: ${product.quantity}`}</div>
                    <div>{`SKU ${product.sku}`}</div>
                </div>
                </div>
            ))}
            </div>
        </div>
        </div>
    </>
  )
}

export default PackageInformation
