import React, { createContext, useCallback, useMemo, useState } from 'react'

export const notificationContext = createContext()

const NotificationWrapper = ({ children }) => {
  const [salesNotifications, setSalesNotifications] = useState([])

  const addNotificationEvent = (event) => {
    if (event.data.category === 'Sales') {
      const newNotification = {
        id: event.data.id,
        payload: event.data,
        read: false
      }
      setSalesNotifications([...salesNotifications, newNotification])
    }
  }

  const markNotificationsAsRead = useCallback(() => {
      setSalesNotifications(salesNotifications.map(notification => ({
        ...notification,
        read: true
      })))
    },
    [salesNotifications]
  )

  navigator.serviceWorker.onmessage = (event) => {
    if (!event.data) return
    if (event.data.type === 'NOTIFICATION_UPDATE') addNotificationEvent(event)
  }

  const values = useMemo(
    () => ({
      salesNotifications,
      markNotificationsAsRead
    }),
    [salesNotifications, markNotificationsAsRead]
  )

  return <notificationContext.Provider value={values}>{children}</notificationContext.Provider>
}

export default NotificationWrapper
