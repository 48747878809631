import { NOTIFICATION_ICONS, store } from './icons'

export const style = {
  storePickup: {
    color: 'medium-purple',
    lightBG: 'bg-semi-light-purple',
    bg: 'bg-medium-purple',
    filter:
      'brightness(0) saturate(100%) invert(21%) sepia(43%) saturate(7425%) hue-rotate(265deg) brightness(101%) contrast(101%)',
    icon: { image: store, alt: 'Paquete retiro en tienda', style: 'h-auto w-7' }
  },
  homeDelivery: {
    color: 'medium-blue',
    lightBG: 'bg-semi-light-blue',
    bg: 'bg-medium-blue',
    filter:
      'brightness(0) saturate(100%) invert(36%) sepia(83%) saturate(2648%) hue-rotate(208deg) brightness(101%) contrast(91%)',
    icon: { image: NOTIFICATION_ICONS.bolt, alt: 'Paquete ship from store', style: 'h-auto w-7' }
  }
}

export const getTimerStyle = (minutes) => {
  let backgroundColor
  let contentColor
  let filter

  if (minutes < 5) {
    backgroundColor = 'bg-[#fbdddd]'
    contentColor = 'text-[#ec5e5e]'
    filter = 'brightness(0) saturate(100%) invert(63%) sepia(70%) saturate(3798%) hue-rotate(321deg) brightness(93%) contrast(98%)'
  } else {
    backgroundColor = 'bg-semi-light-blue'
    contentColor = 'text-medium-blue'
    filter = 'brightness(0) saturate(100%) invert(36%) sepia(83%) saturate(2648%) hue-rotate(208deg) brightness(101%) contrast(91%)'
  }

  return { backgroundColor, contentColor, filter }
}
