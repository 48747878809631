import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import useFetch from '../hooks/useFetch'
import { getPackageInformation } from '../helpers/requests/packagesInformation'
import Header from '../components/Header'
import { style } from '../constants/notifications'
import { PACKAGE_DELIVERY_TYPE } from '../constants/packageStatus'
import Loader from '../components/shared/Loader'
import StoreModal from '../components/PackagePicking/StoreModal'
import PackageInformation from '../components/PackagePicking/PackageInformation'
import PickingAndPacking from '../components/PackagePicking/PickingAndPacking'

const PackagePicking = () => {
  const { packageId } = useParams()
  const [infoPackage, setInfoPackage] = useState({})
  const [viewPackageDetails, setViewPackageDetails] = useState(false)
  const [readyToProcess, setReadyToProcess] = useState(false)
  const [labelErrorMessage, setLabelErrorMessage] = useState('')

  const { isLoading, error } = useFetch(getPackageInformation, setInfoPackage, packageId)

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const bgColor = style[infoPackage.deliveryType]?.bg

  if (error) return <div>Ha ocurrido un error</div>

  return (
    <div className="flex h-fit min-h-full w-full grow justify-center bg-light-gray text-center">
      <StoreModal
        packageData={infoPackage}
        readyToProcess={readyToProcess}
        labelErrorMessage={labelErrorMessage}
        setLabelErrorMessage={setLabelErrorMessage}
      />
      {!isLoading ? (
        <>
          <Header
            name={PACKAGE_DELIVERY_TYPE[infoPackage.deliveryType] || 'Detalle del pedido'}
            to="/"
            color={bgColor || ''}
          />
          <div className={`z-0 flex h-auto w-full flex-col items-center md:mt-24
            md:h-fit md:w-2/3 ${isMobile && bgColor}`}>
            <PackageInformation
              infoPackage={infoPackage}
              viewPackageDetails={viewPackageDetails}
              setViewPackageDetails={setViewPackageDetails}
              isMobile={isMobile}
            />
            <PickingAndPacking
              infoPackage={infoPackage}
              setViewPackageDetails={setViewPackageDetails}
              setReadyToProcess={setReadyToProcess}
              isMobile={isMobile}
              setLabelErrorMessage={setLabelErrorMessage}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default PackagePicking
