import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Modal from '../shared/Modal/Modal'
import Button from '../shared/Button'
import { checkGreen, FEEDBACK_ICONS } from '../../constants/icons'
import ROUTES from '../../constants/routes'
import { ScanContext } from '../../contexts/ScanContext'

const StoreModal = ({ packageData, readyToProcess, labelErrorMessage, setLabelErrorMessage }) => {
  const { setCodes, setSingleScan } = useContext(ScanContext)

  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const closeModal = () => {
    setOpen(false)
    setLabelErrorMessage('')
  }

  const sendToStore = () => {
    setCodes([{ pinflagId: packageData.pinflagId, orderId: packageData.orderId }])
    setSingleScan(true)
    navigate(`${ROUTES.STORAGE}?storePickUp=true`)
  }

  const goHome = () => {
    navigate('/')
  }

  useEffect(() => {
    if (readyToProcess || labelErrorMessage) {
      setOpen(true)
    }
  }, [readyToProcess, packageData, labelErrorMessage])

  return (
    <div>
      <Modal show={open} handleClose={closeModal} customWidth="md:w-2/5 w-full">
        <div className="flex flex-col items-center justify-center">
          {labelErrorMessage ? (
            <div className="flex w-full flex-col items-center gap-2 text-center">
              <img className="w-10" src={FEEDBACK_ICONS.errorIcon} alt="box" />
              <div className="font-semibold">Ha ocurrido un error al intentar generar la etiqueta</div>
              <div className="w-full overflow-hidden break-words text-xs font-light md:text-sm">
                {labelErrorMessage}
              </div>
            </div>
          ) : (
            <>
              <img className="m-5 w-1/6" src={checkGreen} alt="box" />
              <div className="text-xl font-semibold">Pedido procesado con éxito</div>
              <div className="text-base font-light md:text-xl">Puedes almacenar el pedido de inmediato o más tarde.</div>
              <div className="mt-4 flex w-full flex-col">
                <Button
                  color="bg-medium-green"
                  textColor="text-white"
                  shadow="drop-shadow-medium-green"
                  onClick={sendToStore}
                >
                  <div className="text-xl">Almacenar</div>
                </Button>
                <Button textColor="text-text-black" onClick={goHome}>
                  <div className="text-xl">Más tarde</div>
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default StoreModal
