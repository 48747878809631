import React, { useState, useEffect } from 'react'
import { getTimerStyle } from '../../../constants/notifications'
import { NOTIFICATION_ICONS } from '../../../constants/icons'
import Loader from '../../shared/Loader'

const calculateTimeLeft = (deadline) => {
  const now = new Date()
  const difference = new Date(deadline) - now

  if (difference) {
    const minutes = Math.floor(difference / (1000 * 60))
    return minutes
  }
  return null
}

const Timer = ({item, backgroundColor}) => {
  const [deadline] = useState(new Date(new Date(item.createdAt).getTime() + 2 * 60 * 60 * 1000))
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(deadline))

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(deadline))
    }, 10000)

    return () => clearInterval(timer)
  }, [deadline])

  return (
    <div
      className={`flex h-6 w-max items-start justify-center gap-1 rounded-full py-0.5 px-1 md:h-10
         md:items-center md:p-2 ${backgroundColor || getTimerStyle(timeLeft).backgroundColor}`}
    >
      <img
        src={NOTIFICATION_ICONS.timer}
        alt="Tiempo estimado de empaque"
        style={{filter: getTimerStyle(timeLeft).filter}}
      />
      <div className={`${getTimerStyle(timeLeft).contentColor} w-3/4 text-sm md:text-base`}>
        {timeLeft
          ? `${timeLeft < 0 ? 'Atrasado' : `${timeLeft} m`}`
          : <Loader sizeClass="h-4 w-4" marginClass="m-0" />
        }
      </div>
    </div>
  )
}

export default Timer
