import React, { useState } from 'react'

import PackageSelection from './PackageSelection'
import { getLabel } from '../../helpers/requests/label'
import downloadLabel from '../../utils/downloadLabel'
import { print } from '../../constants/icons'
import Button from '../shared/Button'

const PickingAndPacking = ({
    infoPackage, setViewPackageDetails, setReadyToProcess, isMobile, setLabelErrorMessage
}) => {
  const [selectedProducts, setSelectedProducts] = useState([])
  const [isLoadingLabel, setIsLoadingLabel] = useState(false)
  const [isLabelPrinted, setIsLabelPrinted] = useState(false)
  const [viewPacking, setViewPacking] = useState(false)

  const toggleProductSelection = (product) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.some((p) => p.sku === product.sku)
        ? prevSelected.filter((p) => p.sku !== product.sku)
        : [...prevSelected, product]
    )
  }

  const parseBody = () => {
    const packageIds = [{ packageId: infoPackage.packageId }]

    return { 'use_multi_label': true, 'use_single_label': false, packages: packageIds }
  }

  const handleProcessPackage = () => {
    setReadyToProcess(true)
  }

  const handlePrintLabel = async () => {
    try {
      setIsLoadingLabel(true)
      const body = parseBody()
      const response = await getLabel(body)
      if (isMobile) handleProcessPackage()
      else downloadLabel(response.all_labels_url)
      setIsLabelPrinted(true)
    } catch (error) {
      setLabelErrorMessage(JSON.stringify(error.response?.data?.es || error.response || error))
    } finally {
      setIsLoadingLabel(false)
    }
  }

  const handlePacking = () => {
    setViewPacking(true)
  }

  const buttonTitle = () => {
    if (viewPacking || isMobile) return 'Procesar pedido'
    if (!viewPacking && isLabelPrinted) return 'Pasar al packing'
    return 'Imprimir etiqueta'
  }

  const buttonIcon = () => {
    if (!viewPacking && !isLabelPrinted && !isMobile) return <img src={print} alt="Imprimir etiqueta" className="mr-4" />
    return null
  }

  const handleButton = async () => {
    setViewPackageDetails(false)
    if (viewPacking) return handleProcessPackage()
    if (!viewPacking && isLabelPrinted) return handlePacking()
    if (isMobile) {
      await handlePrintLabel()
      return null
    }
    return handlePrintLabel()
  }

  const isDisabledButton = () => {
    if (viewPacking || isMobile) return selectedProducts.length < infoPackage.products.length
    if (!viewPacking && isLabelPrinted) return false
    return isLoadingLabel
  }

  return (
    <div className={`flex w-full flex-col justify-between bg-light-gray pb-10 transition-all duration-500 md:mt-12 md:justify-start  ${isMobile && 'h-full w-full rounded-t-[3rem]'}`}>
      <div className='hidden flex-col items-center md:flex'>
        <div className="h-16 text-xl font-medium">{viewPacking ? 'Packing' : 'Picking'}</div>
        <div className="h-16 opacity-50">
            {viewPacking
            ? 'Selecciona los productos que ya ingresaste en la bolsa'
            : 'Imprime la etiqueta y ve a buscar los productos para armar el pedido'
            }
        </div>
      </div>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="my-6 flex w-[80%] flex-col items-start self-center md:hidden">
            <div className="text-center text-base font-light">Pedido {infoPackage.orderId}</div>
            <div className="text-center text-xs font-light">Cantidad de productos: {infoPackage.products.length}</div>
        </div>
        {infoPackage.products?.map((product) => {
            const isSelected = selectedProducts.some((p) => p.sku === product.sku)
            return (
            <PackageSelection
                key={product.sku}
                isSelected={isSelected}
                product={product}
                toggleProductSelection={toggleProductSelection}
                viewPacking={viewPacking || isMobile}
            />
            )
        })}
      </div>
      <div className="flex w-[90%] justify-center self-center md:w-full">
        <Button
          color={isLabelPrinted || isMobile ? 'bg-medium-green' : 'bg-medium-blue'}
          textColor="text-white"
          shadow="drop-shadow-medium-green"
          onClick={handleButton}
          inactive={isDisabledButton()}
          customRounded="rounded-full"
        >
          <div className="flex items-center justify-center">
            {isLoadingLabel ? (
              <div className="mr-4 h-6 w-6 animate-spin rounded-full border-2 border-medium-gray border-t-transparent" />
            ) : (
              buttonIcon()
            )}
            <div className="text-2xl font-medium">{buttonTitle()}</div>
          </div>
        </Button>
      </div>
    </div>
  )
}

export default PickingAndPacking
