import React, { useCallback, useState } from 'react'

import { Link } from 'react-router-dom'
import { chevronDownIcon } from '../../../constants/icons'
import { getPackageForNotifications } from '../../../helpers/requests/packagesInformation'
import useFetch from '../../../hooks/useFetch'
import Loader from '../../shared/Loader'

import NotificationCard from './NotificationCard'
import { style } from '../../../constants/notifications'
import Timer from './Timer'

const Notifications = () => {
  const [packagesNotifications, setPackagesNotifications] = useState([])

  const setPackages = useCallback((data) => {
    setPackagesNotifications(data)
  }, [])

  const { isLoading } = useFetch(getPackageForNotifications, setPackages)

  if (isLoading) {
    return (
      <div className="mt-10">
        <Loader />
      </div>
    )
  }

  if (!packagesNotifications || packagesNotifications.length === 0) return <div />

  return (
    <div className="flex w-full flex-col gap-2">
        <div className="w-full shrink-0 grow-0">
          <NotificationCard
            title={`Tienes ${packagesNotifications.length} pedidos por armar`}
            items={packagesNotifications}
          >
            <div className="space-y-2">
              {packagesNotifications.map((item) => (
                <Link
                  to={`picking/${item.packageId}`}
                  key={item.packageId}
                  className="relative flex h-24 items-center justify-between rounded-lg bg-white p-3 md:px-8"
                >
                  <div className="flex items-center gap-2 md:w-1/2 md:gap-8">
                    <div className={
                      `rounded-full ${style[item.deliveryType].lightBG} w-1/5 p-2 md:w-auto md:p-4`
                    }>
                      <img
                        src={style[item.deliveryType].icon.image}
                        alt={style[item.deliveryType].icon.alt}
                        className={style[item.deliveryType].icon.style}
                      />
                    </div>
                    <div className='w-3/4 text-left'>
                      <div className="text-sm font-semibold md:text-base">{item.customerFullName}</div>
                      <div className="text-sm text-gray-500 md:text-base">{`${item.productsQuantity || ''} producto${item.productsQuantity === 1 ? '' : 's'} - ${item.orderId}`}</div>
                    </div>
                  </div>
                  <div className="flex h-[60%] w-2/5 items-start md:h-full md:items-center"><Timer item={item} /></div>
                  <div className="absolute top-[50%] right-[5%] translate-x-[-50%] translate-y-[-50%]">
                    <img
                      src={chevronDownIcon}
                      className="h-auto w-3 -rotate-90 md:w-4"
                      alt="Ver más"
                      style={{ filter: style[item.deliveryType]?.filter || '' }}
                    />
                  </div>
                </Link>
              ))}
            </div>
          </NotificationCard>
        </div>
    </div>
  )
}

export default Notifications
