import { HOME_ICONS, checkWhite, greenTruck, bluePackage, closedPackage } from './icons'

export const PACKAGE_BOUGHT = 'bought'
export const PACKAGE_PROCESSED = 'processed'
export const PACKAGE_IN_TRANSIT = 'in_transit'
export const PACKAGE_ON_DELIVERY = 'on_delivery'
export const PACKAGE_RECEIVED = 'received'
export const PACKAGE_STORED = 'stored'
export const PACKAGE_PICKED_UP = 'picked_up'
export const PACKAGE_DELIVERED = 'delivered'

const PACKAGE_STATUS = {
  [PACKAGE_BOUGHT]: {
    name: 'Compra exitosa',
    icon: HOME_ICONS.truckIcon
  },
  [PACKAGE_PROCESSED]: {
    name: 'Procesado',
    icon: HOME_ICONS.truckIcon
  },
  [PACKAGE_IN_TRANSIT]: {
    name: 'En camino',
    icon: HOME_ICONS.truckIcon
  },
  [PACKAGE_ON_DELIVERY]: {
    name: 'En camino',
    icon: HOME_ICONS.truckIcon
  },
  [PACKAGE_RECEIVED]: {
    name: 'Por Almacenar',
    icon: HOME_ICONS.receivedIcon
  },
  [PACKAGE_STORED]: {
    name: 'Almacenado',
    icon: HOME_ICONS.packageIcon
  },
  [PACKAGE_PICKED_UP]: {
    name: 'Almacenado',
    icon: HOME_ICONS.packageIcon
  },
  [PACKAGE_DELIVERED]: {
    name: 'Entregado',
    icon: HOME_ICONS.packageIcon
  }
}

export const PACKAGE_STATUS_FILTER = [
  {
    name: 'En camino',
    status: [PACKAGE_IN_TRANSIT, PACKAGE_ON_DELIVERY]
  },
  {
    name: 'Por Almacenar',
    status: [PACKAGE_RECEIVED]
  },
  {
    name: 'Almacenado',
    status: [PACKAGE_STORED, PACKAGE_PICKED_UP]
  },
  {
    name: 'Entregado',
    status: [PACKAGE_DELIVERED]
  }
]

export default PACKAGE_STATUS


export const PACKAGE_STATUS_MANIFEST = {
  [PACKAGE_BOUGHT]: {
    text: 'Comprado',
    icon: closedPackage,
    style: 'bg-gray-100'
  },
  [PACKAGE_PROCESSED]: {
    text: 'Procesado',
    icon: bluePackage,
    style: 'text-blue bg-blue/10'
  },
  [PACKAGE_ON_DELIVERY]: {
    text: 'Enviado',
    icon: greenTruck,
    style: 'text-green/100 bg-green/10'
  },
  [PACKAGE_DELIVERED]: {
    text: 'Entregado',
    icon: checkWhite,
    style: 'text-white bg-[#037646]'
  }
}

export const PACKAGE_DELIVERY_TYPE = {
  homeDelivery: 'Ship from store',
  storePickup: 'Retiro en Tienda'
}
