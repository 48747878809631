import React from 'react'

const Loader = ({ sizeClass, marginClass }) => (
  <div className={`${marginClass} flex items-center justify-center`}>
    <div className={`${sizeClass} animate-spin rounded-full border-b-2 border-purple`} />
  </div>
)

Loader.defaultProps = {
  sizeClass: 'h-16 w-16',
  marginClass: 'm-6'
}

export default Loader
