const ROUTES = {
  RECEIVED: '/recibido',
  INGRESS: '/ingresar',
  STORAGE: '/almacenar',
  WEIGHING: '/reposicion/pesaje',
  IDENTIFY_PACKAGE: '/almacenar/identificar',
  DELIVER: '/entregar',
  PACKAGE_DETAIL: '/pedidos/:packageId',
  PACKAGES: '/pedidos',
  CLAIM: '/reclamar',
  CLAIM_PACKAGE: '/reclamar/pedido',
  DISTRIBUTION_RETURN: '/devolucion',
  REPLENISHMENT:'/reposicion',
  MANIFESTS:'/manifiestos',
  MANIFESTS_DETAIL:'/manifiestos/:manifestId',
  MANIFEST_PACKAGE:'/manifiestos/:manifestId/:packageId',
  PICKING: '/picking/:packageId'

}

export default ROUTES
