import React, { useState } from 'react'
import PickUp from './PointStats/PickUp'

const PointStats = ({ pointInfo, error }) => {
  const [currentStats, setCurrentStats] = useState('PickUp')

  const STATS_TYPE = {
    PickUp: <PickUp pointInfo={pointInfo} error={error} />
  }

  if (error) {
    return (
      <div className="flex h-full w-full flex-col justify-center bg-light-gray text-center">
        <div className="mt-24">
          Ocurrió un error obteniendo las estadísticas. Inténtelo de nuevo más tarde o contacte al
          soporte Pinflag.
        </div>
      </div>
    )
  }
  return (
    <div className="flex w-full flex-col lg:flex-row">
      <div className="flex w-full flex-col justify-start">
        <div className="my-3 flex gap-3">
          {['PickUp'].map((statType) => (
            <button
            key={statType}
              type="button"
              className={`self-start text-xs font-medium ${
                statType === currentStats ? '' : ' opacity-50'
              } sm:text-sm`}
              onClick={() => setCurrentStats(statType)}
            >
              {statType}
            </button>
          ))}
        </div>
        {STATS_TYPE[currentStats]}
      </div>
    </div>
  )
}

export default PointStats
