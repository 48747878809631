import React from 'react'

const Modal = ({ show, handleClose, children, customWidth = 'w-[80%]' }) => {
  const clickOutside = (e) => {
    if (e.currentTarget !== e.target) return
    handleClose()
  }

  return (
    <div
      className={`fixed top-0 left-0 h-full w-full bg-transparent-black ${!show && 'hidden'} z-20`}
      onClick={clickOutside}
      role="button"
      tabIndex="0"
    >
      <div
        className={`fixed inset-0 m-auto h-fit max-h-[90vh] ${customWidth} max-w-[90vw] 
          cursor-default rounded-3xl bg-white p-8`}
      >
        {children}
      </div>
    </div>
  )
}

export default Modal
