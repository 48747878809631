import React, { useMemo, useState } from 'react'


export const MenuContext = React.createContext()

const MenuProvider = ({ children }) => {
  const [isDistribution, setIsDistribution] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const menuState = useMemo(() => ({
    isDistribution,
    setIsDistribution,
    isMobile,
    setIsMobile
  }), [isDistribution, isMobile])

  return <MenuContext.Provider value={menuState}>{children}</MenuContext.Provider>
}

export default MenuProvider
